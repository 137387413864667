@media only screen and (max-width: 1530px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 60px;
				}
			}
		}
		#in-game-ui {
			.hud {
				#progression-hud {
					transform: scale(0.9);
					transform-origin: top left;
					top: 8px;
					left: 2px;
				}
				#navigation-helper {
					transform: scale(0.79);
					transform-origin: top right;
					right: 58px;
					top: 3px;
				}
				#menu-swapper {
					transform: scale(0.79);
					transform-origin: top right;
				}

				#currency-hud {
					transform: scale(0.79);
					transform-origin: top right;
					right: 58px;
					top: 3px;
				}
				#active-weapons {
					transform: scale(0.9);
					transform-origin: bottom left;
				}
				#gear-abilities {
					transform: scale(0.9);
					transform-origin: bottom left;
					left: 160px;
					bottom: 8px;
				}
				#party-member-list {
					left: 0;
					bottom: 333px;
					transform: scale(0.9);
				}
				#chat {
					transform: scale(0.9);
					transform-origin: bottom left;
					bottom: 135px;
				}
				#inventory-bag {
					transform: scale(0.9);
					transform-origin: bottom right;
				}
				#energy-bar {
					transform: translate(-50%, 0) scale(0.9);
					transform-origin: center center;
					bottom: 43px;
				}
				#enchantment-bar {
					transform-origin: center;
				}
				#buffs-and-debuffs {
					transform: scale(0.79);
					top: 100px;
					left: 15px;
				}
				#health-bar {
					transform: scale(0.79);
					transform-origin: top left;
					top: 20px;
					left: 20px;
				}
				#shields {
					transform: scale(0.79);
					transform-origin: top left;
					top: 75px;
					left: 99px;
				}
				#boss-ui {
					transform: scale(0.95);
					top: -245px;
				}
			}
		}
	}
}
@media only screen and (max-width: 1336px) {
	#ui-overlay {
		#in-game-ui {
			.hud {
				#enchantment-bar {
				}
			}
		}
	}
}
@media only screen and (max-width: 1280px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 60px;
				}
			}
		}
		#in-game-ui {
			.hud {
				#progression-hud {
					transform: scale(0.8);
				}
				#navigation-helper {
					transform: scale(0.8);
				}
				#menu-swapper {
					transform: scale(0.8);
				}
				#currency-hud {
					transform: scale(0.8);
				}
				#active-weapons {
					transform: scale(0.8);
				}
				#gear-abilities {
					transform: scale(0.8);
					left: 145px;
				}
				#party-member-list {
					transform: scale(0.8);
					bottom: 290px;
				}
				#chat {
					transform: scale(0.8);
					bottom: 120px;
				}
				#inventory-bag {
					transform: scale(0.75);
				}
				#energy-bar {
					transform: translate(-50%, 0) scale(0.78);
					bottom: 34px;
				}
				#buffs-and-debuffs {
					transform: scale(0.65);
					top: 70px;
				}
				#health-bar {
					transform: scale(0.65);
				}
				#enchantment-bar {
				}
				#shields {
					transform: scale(0.65);
					top: 64px;
					left: 88px;
				}
				#boss-ui {
					transform: scale(0.8);
					top: -290px;
				}
			}
		}
	}
}
@media only screen and (max-width: 1115px) {
	#ui-overlay {
		#in-game-ui {
			.hud {
				#enchantment-bar {
					right: 25px;
					top: 12px;
				}
				#navigation-helper {
					transform: scale(0.7);
					right: 52px;
					top: 3px;
				}
				#menu-swapper {
					transform: scale(0.7);
				}
				#currency-hud {
					transform: scale(0.7);
					right: 52px;
					top: 3px;
				}
				#buffs-and-debuffs {
					transform: scale(0.55);
					top: 45px;
				}
				#health-bar {
					transform: scale(0.5);
				}
				#shields {
					transform: scale(0.5);
					top: 55px;
					left: 70px;
				}
			}
		}
	}
}
@media only screen and (max-width: 992px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 60px;
				}
			}
		}
		#in-game-ui {
			.hud {
				#progression-hud {
					transform: scale(0.7);
				}
				#currency-hud {
					transform: scale(0.7);
					right: 52px;
					top: 3px;
				}
				#active-weapons {
					transform: scale(0.7);
				}
				#gear-abilities {
					transform: scale(0.7);
					left: 128px;
				}
				#party-member-list {
					transform: scale(0.7);
					bottom: 245px;
				}
				#chat {
					transform: scale(0.7);
					bottom: 104px;
				}
				#inventory-bag {
					transform: scale(0.7);
				}
				#energy-bar {
					transform: translate(-50%, 0) scale(0.69);
					bottom: 33px;
				}
				#boss-ui {
					transform: scale(0.7);
					top: -270px;
				}
			}
		}
	}
}
@media only screen and (max-width: 864px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 60px;
				}
			}
		}
		#in-game-ui {
			.hud {
				#progression-hud {
					transform: scale(0.7);
				}
				#navigation-helper {
					transform: scale(0.7);
				}
				#menu-swapper {
					transform: scale(0.7);
				}
				#currency-hud {
					transform: scale(0.7);
				}
				#active-weapons {
					transform: scale(0.65);
				}
				#gear-abilities {
					transform: scale(0.65);
					left: 118px;
					bottom: 7px;
				}
				#party-member-list {
					transform: scale(0.65);
					bottom: 220px;
				}
				#chat {
					transform: scale(0.65);
					bottom: 96px;
				}
				#inventory-bag {
					transform: scale(0.6);
				}
				#energy-bar {
					transform: translate(-50%, 0) scale(0.6);
					bottom: 30px;
				}
				#enchantment-bar {
				}
				#buffs-and-debuffs {
					top: 40px;
				}
				#health-bar {
					transform: scale(0.43);
				}
				#shields {
					transform: scale(0.43);
					top: 50px;
					left: 65px;
				}
				#boss-ui {
				}
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 60px;
				}
			}
		}
		#in-game-ui {
			.hud {
				#progression-hud {
					transform: scale(0.7);
				}
				#menu-swapper {
					transform: scale(0.7);
				}
				#currency-hud {
					transform: scale(0.7);
				}
				#active-weapons {
					transform: scale(0.6);
				}
				#gear-abilities {
					transform: scale(0.6);
					left: 113px;
					bottom: 6x;
				}
				#party-member-list {
					transform: scale(0.6);
					bottom: 200px;
				}
				#chat {
					transform: scale(0.6);
					bottom: 90px;
				}
				#inventory-bag {
					transform: scale(0.6);
				}
				#energy-bar {
					transform: translate(0, 0) scale(0.48);
					right: 170px;
					left: unset;
					bottom: 34px;
				}
				#health-bar {
				}

				#shields {
				}
				#boss-ui {
				}
			}
		}
	}
}
//Energy bar query so it doesn't overlap active weapons
@media only screen and (max-width: 714px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 60px;
				}
			}
		}
		#in-game-ui {
			.hud {
				#energy-bar {
					transform: translate(0, 0) scale(0.48);
					transform-origin: center;
					right: 140px;
				}
			}
		}
	}
}
@media only screen and (max-width: 675px) {
	#ui-overlay {
		#in-game-ui {
			.hud {
				#energy-bar {
					transform: translate(0, 0) scale(0.48);
					transform-origin: center;
					right: 110px;
				}
			}
		}
	}
}
@media only screen and (max-width: 650px) {
	#ui-overlay {
		#in-game-ui {
			.hud {
				#energy-bar {
					transform: translate(0, 0) scale(0.48);
					transform-origin: center;
					right: 85px;
				}
			}
		}
	}
}
@media only screen and (max-width: 625px) {
	#ui-overlay {
		#in-game-ui {
			.hud {
				#energy-bar {
					transform: translate(0, 0) scale(0.48);
					transform-origin: center;
					right: 60px;
				}
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	#ui-overlay {
		#in-game-ui {
			.hud {
				#energy-bar {
					transform: translate(0, 0) scale(0.48);
					transform-origin: center;
					right: 35px;
				}
			}
		}
	}
}

//Anything greater then 1530px wide and less then 640px tall
@media only screen and (min-width: 1530px) and (max-height: 896px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: -40px;
				}
			}
		}
		#in-game-ui {
			.hud {
				#progression-hud {
					transform: scale(0.9);
					transform-origin: top left;
					top: 8px;
					left: 2px;
				}
				#navigation-helper {
					transform: scale(0.79);
					transform-origin: top right;
					right: 58px;
					top: 3px;
				}
				#menu-swapper {
					transform: scale(0.79);
					transform-origin: top right;
				}
				#currency-hud {
					transform: scale(0.79);
					transform-origin: top right;
					right: 58px;
					top: 3px;
				}
				#active-weapons {
					transform: scale(0.9);
					transform-origin: bottom left;
				}
				#gear-abilities {
					transform: scale(0.9);
					transform-origin: bottom left;
					left: 160px;
					bottom: 8px;
				}
				#party-member-list {
					transform: scale(0.85);
					bottom: 334px;
					left: 0;
				}
				#chat {
					transform: scale(0.9);
					transform-origin: bottom left;
					bottom: 135px;
				}
				#inventory-bag {
					transform: scale(0.9);
					transform-origin: bottom right;
				}
				#energy-bar {
					transform: translate(-50%, 0) scale(0.9);
					transform-origin: center center;
					bottom: 43px;
				}
				#enchantment-bar {
					transform-origin: center;
					top: 8px;
				}
				#buffs-and-debuffs {
					transform: scale(0.79);
					top: 100px;
					left: 20px;
				}
				#health-bar {
					transform: scale(0.79);
					transform-origin: top left;
					top: 20px;
					left: 20px;
				}
				#shields {
					transform: scale(0.79);
					transform-origin: top left;
					top: 75px;
					left: 99px;
				}
				#boss-ui {
					transform: scale(0.95);
					top: -245px;
				}
			}
		}
	}
}
//landing page logo edits
@media only screen and (min-height: 1201px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: -90px;
				}
			}
		}
	}
}


@media only screen and (min-height: 512px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 181px;
				}
			}
		}
	}
}
@media only screen and (min-height: 640px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 118px;
				}
			}
		}
	}
}
@media only screen and (min-height: 768px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 67px;
				}
			}
		}
	}
}
@media only screen and (min-height: 877px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: 23px;
				}
			}
		}
	}
}
@media only screen and (min-height: 969px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: -4px;
				}
			}
		}
	}
}
@media only screen and (min-height: 1200px) {
	#ui-overlay {
		#main-menu-ui {
			.logo {
				&.logo-landing {
					top: -90px;
				}
			}
		}
	}
}
